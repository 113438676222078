<template>
  <div class="Samples">
    <Header />

    <div class="PageContentWrap">
      <!-- Filled samples table -->
      <Accordion
        :title="mixWB('TABLE_FOR_THE_REPORT')"
        :bodyComponent="Table"
        :bodyComponentProps="{
          samples: sortedScreeningSamples,
        }" />

      <!-- All samples -->
      <Accordion
        :title="mixWB('LIST_OF_SAMPLES')"
        :bodyComponent="SamplesList" />
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import Header from '@/components/ResultItems/Header.vue'
import SamplesList from '@/components/ResultItems/SamplesList.vue'
import Table from '@/components/PDF/AnalysisResults/Table.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'

export default {
  name: 'Samples',
  data() {
    return {
      Table,
      SamplesList,
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
    ]),
    sortedScreeningSamples() {
      return sortBy(this.screeningSamples, ['sampleNumber', 'equivalentNumber', 'assessNumber'])
    },
  },
  components: {
    Header,
    Accordion,
  },
}
</script>

<style lang="stylus" scoped>
  .Samples
    display block

  .PageContentWrap
    max-width 1000px
    margin 0 auto
</style>
