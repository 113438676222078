<template>
  <tr
    class="SampleRow"
    :class="overallClass">

    <td
      v-if="isEquivalentSample"
      :class="['Number', overallClass]">
      <a
        :href="'#sample-p' + sample.equivalentSampleNumber"
        @click.prevent="onSampleClick()">
        T{{ sample.equivalentNumber }} (P{{ sample.equivalentSampleNumber }})
      </a>
    </td>
    <td
      v-else
      :class="overallClass">
      <a
        :href="'#sample-p' + sample.sampleNumber"
        @click.prevent="onSampleClick()">
        P{{ sample.sampleNumber }}
      </a>
    </td>

    <td class="Title">
      <span>{{ sample.getFullTitle() }}</span>
      <span
        v-if="sample.kindOfSample === 'coating'"
        class="SpecialSampleText">
        {{ mixWB('SAMPLE_OF_COATING') }}
      </span>
      <span
        v-if="sample.kindOfSample === 'underlining'"
        class="SpecialSampleText">
        {{ mixWB('SAMPLE_OF_UNDERLINING') }}
      </span>
      <span
        v-if="sample.otherScreeningID"
        class="SpecialSampleText">
        {{ mixWB('TAKEN_FROM_OTHER_PROJECT_X', [sample.getRelatedScreeningCaseNumber()]) }}
      </span>
    </td>

    <td
      v-for="group in testGroupValues"
      :key="group.id"
      :class="group.styleClass">
      <span v-if="!group.hasAnyValues">i.a.</span>
      <template
        v-else
        v-for="item in group.items">
        <span
          :key="item.id"
          v-if="item.result !== null && item.result !== 0"
          :class="{ 'BoldText': item.result === 2 }">
          {{ `${
            group.items.length > 1 ? mixWB(item.wordbook) : ''
          } ${
            item.isBelowValue ? decodeURI('%3C') : ''
          } ${
            item.isAboveValue ? decodeURI('%3E') : ''
          } ${
            mixFormatNumber(item.value)
          } ${ item.id === 'cpScreening' ? '*' : '' }` }}
          <br />
        </span>
        <span
          :key="item.id"
          v-else-if="item.id === 'cpScreening' && testResult.cpScreeningAutoProven"
          class="BoldText">
          {{ item.value }}
        </span>
      </template>
    </td>
  </tr>
</template>

<script>
import { testResultsGroups } from '@/globals/javascript/_util/test-results'
import { mapGetters } from 'vuex'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'SampleRow',
  props: {
    sample: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      testResultsGroups,
    }
  },
  computed: {
    ...mapGetters([
      'screeningTestResults',
      'screeningRelatedScreenings',
    ]),
    isEquivalentSample() {
      return !!this.sample.equivalentNumber
    },
    testResult() {
      // Get test result from related screening
      if (this.sample.otherScreeningID) {
        const relatedScreening = this.screeningRelatedScreenings.find(
          (x) => x.data.id === this.sample.otherScreeningID,
        )
        return relatedScreening?.testResults?.find(
          (x) => x.sampleNumber === this.sample.equivalentSampleNumber,
        )
      }

      // Get test result from this screening
      if (!this.screeningTestResults) {
        return false
      }
      return this.screeningTestResults.find(
        (x) => x.sampleNumber === this.sample.sampleNumber
        || x.sampleNumber === this.sample.equivalentSampleNumber,
      )
    },
    overallClass() {
      if (!this.testResult) {
        return ''
      }

      if (
        this.testResult.overallResult === 2
        || this.testResult.metalsAboveThreshold
        || this.testResult.cpScreeningAutoProven
      ) {
        return 'ItemHazardousWaste'
      }

      if (this.testResult.overallResult === 1) {
        return 'ItemContaminated'
      }

      if (this.testResult.overallResult === 0) {
        return 'ItemClean'
      }

      return 'ItemNotAnalysed'
    },
    testGroupValues() {
      if (!this.testResult) {
        return []
      }

      return testResultsGroups.reduce((prev, testGroup) => {
        if (['CP_SHORT', 'CP_MEDIUM'].includes(testGroup.id)) {
          return prev
        }
        // Get all tests in groups
        const groupData = testGroup.tests.reduce((prevy, test) => {
          const item = this.testResult.results.find((x) => x.id === test.id)
          item.wordbook = test.wordbook

          // Update group result
          if (item.result !== null) {
            if (prevy.groupResult === null) {
              prevy.groupResult = item.result
            }
            else if (item.result > prevy.groupResult) {
              prevy.groupResult = item.result
            }
          }

          // Update group for having values
          if (!prevy.hasAnyValues && (item.value !== null || item.isTooLowToMeasure)) {
            prevy.hasAnyValues = true
          }

          prevy.items.push(item)
          return prevy
        }, {
          groupResult: null,
          id: testGroup.id,
          items: [],
          hasAnyValues: false,
        })

        // Check for metals above threshold
        if (testGroup.id === '7-metals' && groupData.groupResult !== 2) {
          if (this.testResult.metalsAboveThreshold) {
            groupData.groupResult = 2
          }
        }

        // Check for CP screening auto proven
        if (testGroup.id === 'CP_SCREENING' && groupData.groupResult !== 2) {
          if (this.testResult.cpScreeningAutoProven) {
            groupData.groupResult = 2
          }
        }

        // Add style class to group
        groupData.styleClass = 'ItemNotAnalysed'

        if (groupData.groupResult === 0) {
          groupData.styleClass = 'ItemClean'
        }
        if (groupData.groupResult === 1) {
          groupData.styleClass = 'ItemContaminated'
        }
        if (groupData.groupResult === 2) {
          groupData.styleClass = 'ItemHazardousWaste'
        }

        prev.push(groupData)
        return prev
      }, [])
    },
  },
  methods: {
    onSampleClick() {
      const sampleNumber = this.sample.sampleNumber || this.sample.equivalentSampleNumber
      const element = document.getElementById(`sample-p${ sampleNumber }`)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .SampleRow
    span
      display block
    td
      padding 5px 10px
      text-align center
      vertical-align center
      white-space nowrap
      &:nth-child(2)
        white-space normal
    td,
    span
      font-size 0.75rem
      a
        text-decoration underline
    .Title
      text-align left
      .SpecialSampleText
        color $color_grey
        font-style italic

    .BoldText
      font-weight bold

    td.ItemClean
      background-color $color_report_green
      border-bottom 1px solid $color_report_green_alt
    td.ItemContaminated
      background-color $color_report_yellow
      border-bottom 1px solid $color_report_yellow_alt
    td.ItemHazardousWaste
      background-color $color_report_red
      border-bottom 1px solid $color_report_red_alt
</style>
