<template>
  <tr class="AssessRow">
    <td>
      <a
        :href="'#sample-v' + sample.assessNumber"
        @click.prevent="onSampleClick()">
        V{{ sample.assessNumber }}
      </a>
    </td>
    <td class="Title">{{ sample.getFullTitle() }}</td>
    <td
      class="Reason"
      colspan="5">
      {{ mixGetSampleStatusWordbook(sample.status) }}
    </td>
  </tr>
</template>

<script>
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'AssessRow',
  props: {
    sample: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSampleClick() {
      const element = document.getElementById(`sample-v${ this.sample.assessNumber }`)

      if (!element) {
        return
      }

      scrollTo({ element, offset: -60, duration: 500 })
    },
  },
}
</script>

<style lang="stylus" scoped>
  .AssessRow
    td
      padding 5px 10px
      text-align center
      vertical-align center
      white-space nowrap
      border-bottom 1px solid #fff
      font-size 0.75rem
      &:nth-child(2)
        white-space normal
      a
        text-decoration underline
    .Title,
    .Reason
      text-align left
</style>
