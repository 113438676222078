<template>
  <div
    id="table-of-samples"
    class="Table">
    <div class="TableWrap">
      <table
        ref="SamplesOverview"
        class="SamplesOverview">

        <!-- Samples -->
        <template v-for="sample in samples">
          <!-- Sample header -->
          <template v-if="sample.id === firstSampleID">
            <tr
              class="Headline"
              :key="sample.sampleNumber">
              <td colspan="2"/>
              <td colspan="5">{{ mixWB('ANALYSIS_RESULTS_MG_KG') }}</td>
            </tr>
            <tr
              class="Legend"
              :key="sample.getFullTitle() + firstSampleID">
              <td class="SampleNumber">{{ mixWB('SAMPLE_NUMBER_SHORT') }}</td>
              <td class="Comment">{{ mixWB('TYPE') }}</td>
              <td class="PCB">{{ mixWB('SAMPLE_PCB') }}</td>
              <td class="Metals">{{ mixWB('SAMPLE_7_METALS') }}</td>
              <td class="PAH">{{ mixWB('SAMPLE_9PAH') }}</td>
              <td class="Asbestos">{{ mixWB('SAMPLE_ASBEST') }}</td>
              <td class="CPScreening">{{ mixWB('CP_SCREENING') }}</td>
            </tr>
          </template>
          <SampleRow
            v-if="sample.sampleNumber"
            :key="sample.id"
            :sample="sample" />

          <!-- Equivalent header -->
          <tr
            class="Legend"
            v-if="sample.id === firstEquivalentID"
            :key="sample.getFullTitle()">
            <td class="SampleNumber">{{ mixWB('EQUIVALENT_NO') }}</td>
            <td class="Comment">{{ mixWB('TYPE') }}</td>
            <td class="PCB">{{ mixWB('SAMPLE_PCB') }}</td>
            <td class="Metals">{{ mixWB('SAMPLE_7_METALS') }}</td>
            <td class="PAH">{{ mixWB('SAMPLE_9PAH') }}</td>
            <td class="Asbestos">{{ mixWB('SAMPLE_ASBEST') }}</td>
            <td class="CPScreening">{{ mixWB('CP_SCREENING') }}</td>
          </tr>
          <SampleRow
            v-if="sample.equivalentNumber"
            :key="sample.id"
            :sample="sample" />

          <!-- Assess header -->
          <tr
            class="Legend"
            v-if="sample.id === firstAssessID"
            :key="sample.getFullTitle()">
            <td class="SampleNumber">{{ mixWB('ASSESSMENT_NO') }}</td>
            <td class="Comment">{{ mixWB('TYPE') }}</td>
            <td
              class="Assessment"
              colspan="5">{{ mixWB('ASSESSMENT') }}</td>
          </tr>
          <AssessRow
            v-if="sample.assessNumber"
            :key="sample.id"
            :sample="sample" />
        </template>
      </table>
    </div>

    <!-- Table foot notes -->
    <div class="FootNotes">
      <span>* {{ mixWB('SEE_THE_VALUES_UNDER_THE_INDIVIDUAL_TYPES') }}</span>
    </div>
  </div>
</template>

<script>
import SampleRow from '@/components/PDF/AnalysisResults/SampleRow.vue'
import AssessRow from '@/components/PDF/AnalysisResults/AssessRow.vue'

export default {
  name: 'Table',
  props: {
    samples: {
      type: Array,
      required: true,
    },
  },
  computed: {
    firstSampleID() {
      const sample = this.samples.find((x) => x.sampleNumber)
      if (!sample) {
        return false
      }
      return sample.id
    },
    firstAssessID() {
      const sample = this.samples.find((x) => x.assessNumber)
      if (!sample) {
        return false
      }
      return sample.id
    },
    firstEquivalentID() {
      const sample = this.samples.find((x) => x.equivalentNumber)
      if (!sample) {
        return false
      }
      return sample.id
    },
  },
  components: {
    SampleRow,
    AssessRow,
  },
}
</script>

<style lang="stylus" scoped>
  .Table
    span
      display block

  .TableWrap
    border 1px solid $color_grey_dark

  .SamplesOverview
    border-collapse collapse
    width 100%
    tr
      background-color #fff
      border-bottom 1px solid #fff
      &:nth-child(odd)
        background-color $color_grey_lightest
        border-bottom 1px solid $color_grey_lightest
    td
      padding 5px 10px
      font-size 0.875em
    tr.Headline
      background-color $color_grey_dark
      td
        color #fff
        font-size 1.125rem
        padding 10px
        border-bottom 1px solid $color_grey_dark
        &:last-child
          text-align center
    tr.Legend
      background-color $color_grey_dark
      border-bottom 1px solid $color_grey_dark
      td
        color #fff
        text-align center
        &:nth-child(2)
          text-align left
        &.Assessment
          text-align left

    .SampleNumber
      width 100px
    .PCB
      width 50px
    .Metals
      width 100px
    .PAH
      width 60px
    .Asbestos
      width 60px
    .CPScreening
      width 60px

  .FootNotes
    padding-top 5px
    span
      font-size 0.875em
</style>
