<template>
  <div class="SamplesListItem">
    <!-- Number -->
    <div
      class="Number"
      v-if="item.sample">
      <span v-if="item.sample.sampleNumber">P{{ item.sample.sampleNumber }}</span>
      <span v-if="item.sample.assessNumber">S{{ item.sample.assessNumber }}</span>
      <span v-if="item.sample.equivalentNumber">T{{ item.sample.equivalentNumber }}</span>
    </div>

    <!-- Info -->
    <div class="Info">
      <span class="Title">{{ item.type.getTitle({ category: true, details: true }) }}</span>
      <span v-if="item.sample.kindOfSample === 'coating'">({{ mixWB('COATING') }})</span>
      <span v-if="item.sample.kindOfSample === 'underlining'">({{ mixWB('UNDERLINING') }})</span>
      <span v-if="item.type.getCoatingText()">{{ item.type.getCoatingText() }}</span>
      <span
        v-if="item.sample.kindOfSample === 'material'">
        {{ item.type.getMaterialSampleList() }}
      </span>
      <span
        v-if="item.sample.kindOfSample === 'coating'">
        {{ item.type.getCoatingSampleList() }}
      </span>
      <span
        v-if="item.sample.kindOfSample === 'underlining'">
        {{ item.type.getUnderliningSampleList() }}
      </span>
      <span
        v-if="item.type.unitIDs.length">{{ mixWB('UNITS') }}: {{ item.type.getUnitsList() }}</span>
      <span
        v-else>{{ getBuildingsText() }}</span>
    </div>

    <!-- Status -->
    <div
      class="Status"
      v-if="item.sample">
      <span
        class="Done"
        v-if="item.sample.status === 'done'">{{ mixWB('DONE') }}</span>
      <div
        class="SkippedWrap"
        v-else-if="item.sample.status.includes('skip-') || item.sample.isAutoAssessment">
        <span class="Skipped">{{ mixWB('SKIPPED') }}</span>
        <span>{{ mixGetSampleStatusWordbook(item.sample.status) }}</span>
      </div>
      <div
        class="EquivalentWrap"
        v-else-if="item.sample.status === 'equivalent'">
        <span class="Equivalent">{{ mixWB('SAME_AS') }}</span>
        <span>P{{ item.sample.equivalentSampleNumber }}</span>
      </div>
      <span
        class="NotDone"
        v-else>{{ mixWB('NOT DONE') }}</span>
    </div>

    <!-- Images -->
    <div class="ImageListWrap">
      <ImageList
        v-if="sampleImages.length"
        :images="sampleImages"
        :title="mixWB('CLOSE_UP_AND_FROM_A_DISTANCE')"
        imageboxSize="small"
        padding="none" />
    </div>
  </div>
</template>

<script>
import ImageList from '@/components/ResultItems/ImageList'
import { mapGetters } from 'vuex'

export default {
  name: 'SamplesListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      savedImages: [],
      showSampleTable: false,
    }
  },
  computed: {
    ...mapGetters([
      'selectedBuildings',
    ]),
    sampleImages() {
      const images = []

      if (this.item.sample.images.close) {
        images.push(this.item.sample.images.close)
      }
      if (this.item.sample.images.away) {
        images.push(this.item.sample.images.away)
      }
      return images
    },
  },
  methods: {
    getBuildingsText() {
      const buildingsNumbers = this.type.buildings.reduce((prev, buildingID) => {
        const buildingData = this.selectedBuildings.find((x) => x.buildingID === buildingID)
        if (buildingData && buildingData.data) {
          prev.push(buildingData.data.Bygningsnr)
        }
        return prev
      }, [])

      return `${ this.mixWB('BUILDING') }: ${ buildingsNumbers.join(', ') }`
    },
  },
  components: {
    ImageList,
  },
}
</script>

<style lang="stylus" scoped>
  .SamplesListItem
    display flex
    align-items center
    height 100%
    flex-wrap wrap

  .Number
    flex-shrink 0
    width 30px
    flex-center-children()

  .Info
    padding 15px
    width 400px
    span
      display block
    .Title
      margin-bottom 2.5px
      font-weight bold
    .Units,
    .Buildings,
    .Analyzes
      font-size 0.875rem
      color $color_grey

  .Status
      flex-grow 2
    .SkippedWrap
      display flex
      align-items center
      .Skipped
        margin-right 10px
    .EquivalentWrap
      display flex
      align-items center
      .Equivalent
        margin-right 10px
    .Done,
    .Skipped,
    .Equivalent,
    .NotDone
      display block
      circle(50px)
      background-color $color_grey
      flex-center-children()
      text-transform uppercase
      font-weight bold
      font-size 0.75rem
      color #fff
      text-align center
    .Done
      background-color $color_primary
    .Skipped
      background-color $color_yellow_dark
    .Equivalent
      background-color $color_purple_dark

  .ImageListWrap
    display block
    flex-shrink 0
</style>
