<template>
  <div class="SamplesList">
    <!-- Auto assessments -->
    <span class="SectionTitle">
      {{ mixWB('AUTOMATIC_ASSESSMENTS') }} ({{ samplesIntoPlaces.autoAssessments.length }})
    </span>
    <SamplesListItem
      v-for="item in samplesIntoPlaces.autoAssessments"
      :key="item.sample.id"
      :item="item" />

    <!-- Outside -->
    <span class="SectionTitle">
      {{ mixWB('OUTSIDE') }} ({{ samplesIntoPlaces.outside.length }})
    </span>
    <SamplesListItem
      v-for="item in samplesIntoPlaces.outside"
      :key="item.sample.id"
      :item="item" />

    <!-- Inside -->
    <span class="SectionTitle">
      {{ mixWB('INSIDE') }} ({{ samplesIntoPlaces.inside.length }})
    </span>
    <SamplesListItem
      v-for="item in samplesIntoPlaces.inside"
      :key="item.sample.id"
      :item="item" />

    <!-- Other -->
    <span class="SectionTitle">
      {{ mixWB('OTHER') }} ({{ samplesIntoPlaces.other.length }})
    </span>
    <SamplesListItem
      v-for="item in samplesIntoPlaces.other"
      :key="item.sample.id"
      :item="item" />
  </div>
</template>

<script>
import SamplesListItem from '@/components/ResultItems/SamplesListItem.vue'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash-es'

export default {
  name: 'SamplesList',
  computed: {
    ...mapGetters([
      'categories',
      'typesWithSamples',
      'screeningSamplesAsObject',
      'screeningSamples',
      'screeningTypes',
    ]),
    samplesIntoPlaces() {
      const myObject = {
        outside: [],
        inside: [],
        other: [],
        autoAssessments: [],
      }

      this.screeningSamples.forEach((sample) => {
        // const type = this.screeningTypes.find((x) => x.id === sample.typeID)
        let type = null
        if (sample.typeID) {
          type = this.screeningTypes.find((x) => x.id === sample.typeID)
        }
        // Pre 1.14.0
        else {
          type = this.screeningTypes.find((x) => x.id === sample.id)
        }
        const category = this.categories.find((x) => x.id === type.categoryID)

        if (sample.isAutoAssessment) {
          myObject.autoAssessments.push({
            type,
            sample,
            categoryPosition: 0,
          })
          return
        }

        myObject[category.place].push({
          type,
          sample,
          categoryPosition: category.position,
        })
      })

      myObject.outside = sortBy(myObject.outside, ['categoryPosition', 'type.createdAt.seconds'])
      myObject.inside = sortBy(myObject.inside, ['categoryPosition', 'type.createdAt.seconds'])
      myObject.other = sortBy(myObject.other, ['categoryPosition', 'type.createdAt.seconds'])

      return myObject
    },
  },
  components: {
    SamplesListItem,
  },
}
</script>

<style lang="stylus" scoped>
  .SamplesList
    display block

  .SectionTitle
    display block
    margin-top 20px
    text-transform uppercase
    padding-left 20px
    padding-bottom 5px
    border-bottom 1px solid $color_grey_lighter
    &:first-child
      margin-top 0

</style>
